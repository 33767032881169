import './css/local.css';
import './css/animation.css';
import './css/fonts.css'
import React, { Suspense } from 'react';
import Navbar from './components/Navbar'
import Hero from './components/Hero'

const Features = React.lazy(() => import('./components/Features'))
const Cards = React.lazy(() => import('./components/Cards'))
const ContactForm = React.lazy(() => import('./components/Contact_Form'))
const Footer = React.lazy(() => import('./components/Footer'))

function App() {
  return (
    <div>
        <Navbar />
        <div className="container-fluid main">
            <Hero />
            <div className="section-divider" id="about_me" />
            <Suspense fallback={<div></div>}>
              <Features />
            </Suspense>
            <div className="section-divider" id="work" />
            <Suspense fallback={<div></div>}>
              <Cards />
            </Suspense>
            <div className="section-divider" id="contact" />
            <Suspense fallback={<div></div>}>
              <ContactForm />
            </Suspense>
            <div className="section-divider" />
            <Suspense fallback={<div></div>}>
              <Footer />
            </Suspense>
        </div>
    </div>
    
      
  );
}

export default App;
