import { BsCode } from 'react-icons/bs'

const myName = ' Bobby McMillen'

const Navbar = () => {
    return (
        <nav className="navbar fixed-top navbar-expand-lg navbar-dark bg-dark">
            <div className="container-fluid">
                <a className="navbar-brand ps-4" href="#home">
                    { <BsCode className="brand-icon" />}
                    {myName}
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse justify-content-end" id="navbarNavAltMarkup">
                    <div className="navbar-nav">
                        <a className="nav-link px-4 slide-in-top bigScreen active" aria-current="page" href="#home">Home</a>
                        <a className="nav-link px-4 smallScreen active" aria-current="page" href="#home">Home</a>
                        <a className="nav-link px-4 slide-in-top-2 bigScreen" href="#about_me">About Me</a>
                        <a className="nav-link px-4 smallScreen" href="#about_me">About Me</a>
                        <a className="nav-link px-4 slide-in-top-3 bigScreen" href="#work">Work</a>
                        <a className="nav-link px-4 smallScreen" href="#work">Work</a>
                        <a className="nav-link px-4 slide-in-top-4 bigScreen" href="#contact">Contact</a>
                        <a className="nav-link px-4 smallScreen" href="#contact">Contact</a>
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default Navbar
