import Coding_Guy from '../assets/images/Coding.svg'

const Hero = () => {
    return (
        <div className="container-fluid col-xxl-8 px-4 py-5" id="home">
            <div className="row h-100 flex-lg-row-reverse align-items-center justify-content-center g-5 py-5">
                <div className="col-10 col-sm-8 col-lg-6">
                    <img src={Coding_Guy} className="d-block mx-lg-auto img-fluid" alt="Guy Coding" width="700" height="700" />
                </div>
                <div className="col-lg-6">
                    <h2 className="fw-bold lh-1 mb-3 text-effect">Hi, my name is</h2>
                    <p className="display-5 fw-bold lh-1 mb-3 text-effect">Bobby McMillen</p>
                    <p className="lead text-white">
                        I help clients enjoy the beauty and functionality of technology through web development. My focus is on meeting your tech needs using some of the latest technologies. 
                    </p>
                    <div className="d-grid gap-2 d-md-flex justify-content-md-start">
                        <a href="#contact">
                            <button type="button" className="btn btn-lg btn-cta px-4 me-md-2">Let's work together!</button>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Hero
